.trafficFilterBtn {
  width: 8.5em;
  height: 3em;
  background: rgba(217, 217, 217, 0.25);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, border 0.3s ease;
}

/* Hover state - lighten the background */
.trafficFilterBtn:hover {
  background: rgba(237, 237, 237, 0.5);
}

/* Active state - darker background */
.trafficFilterBtn:active {
  background: rgba(190, 190, 190, 0.6);
  border: 2px solid rgba(170, 170, 170, 0.8); /* Optional border change for active state */
}

/* Active state styling */
.trafficFilterBtn.active {
  background-color: #a0a0a0;
  border: 2px solid #808080;
  color: #fff;
  font-weight: bold;
}

@media (max-width: 768px) {
  div {
    grid-template-columns: 1fr; /* Stack items in a single column */
    text-align: center;
    gap: 1.5rem;
  }
}