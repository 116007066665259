@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Raspoutine';
  src: url('./assets/fonts/RaspoutineDemiBold_TB.otf') format('opentype');
  font-weight: bold; /* Adjust weight if necessary */
  font-style: normal;  /* Adjust style if necessary */
}


@font-face {
  font-family: 'Raspoutine';
  src: url('./assets/fonts/RaspoutineClassic_TB.otf') format('opentype');
  font-weight: normal; /* Adjust weight if necessary */
  font-style: normal;  /* Adjust style if necessary */
}

/* *{
  outline :1px solid limegreen !important; 
 } */


 ul::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

.scrim.gradient.gradientUp.active {
  visibility: hidden;
}



html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Raspoutine", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7a7f9d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #21295c;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #21295c;
}

.ita-search::placeholder{
  color: black; 
  opacity: 1; 
}