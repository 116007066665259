.weather-widget {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.6px);
  -webkit-backdrop-filter: blur(1.6px);
    max-width: 100%;
    text-align: center;
    margin: 0px 20px;
    padding: 0em .5em;
  }
  
  .weather-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  
  .weather-header img {
    width: 5em;
    height: auto;
  }
  
  .weather-info {
    margin-top: 0px;
  }
  
  .temperature {
    font-size: 1rem;
    font-weight: bold;
    color: #ff6347;
  }
  
  p {
    margin: 0px 0;
  }
  