/* Ensure the video player container maintains the correct aspect ratio and scales responsively */
.video-player-container {
    width: 100%;
    margin: 0;
    padding: 0;
    aspect-ratio: 4/3; /* Maintain a consistent 16:9 aspect ratio */
    position: relative;
    overflow: hidden; /* Prevent controls from overflowing */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black; /* Add a background color to prevent strange gaps */
  }
  
  /* Ensure video player fills the entire container */
  .vds-player {
    width: 100%;
    height: auto;
  }
  
  /* Customize controls for small screens */
  @media (max-width: 576px) {
    .vds-controls {
      font-size: 0.7rem; /* Make controls smaller for smaller screens */
    }
  
    .vds-button {
      width: 25px;
      height: 25px; /* Smaller buttons */
    }
  
    .vds-time-slider {
      height: 2px; /* Thinner progress bar */
    }
  
    .vds-slider-thumb {
      width: 8px;
      height: 8px; /* Smaller slider thumb */
    }
  
    .vds-slider-preview {
      max-width: 100px; /* Limit the size of the preview thumbnails */
    }

    .video-player{
        width: fit-content;
    }
  }
  
  /* Flexbox to handle controls alignment properly */
  .vds-controls-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  
  /* Ensure the video fills the container without leaving black gaps */
  video {
    width: 100% ;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the entire area */
  }
  
  /* Prevent any unexpected margins or padding from the player */
  .vds-player, .vds-controls, .vds-slider, .vds-play-button, .vds-fullscreen-button {
    margin: 0;
    padding: 0;
  }
  